<template lang="html">

  <section class="carrousel-2 container" v-if="dataContent && Object.values(dataContent.slider).length != 0">
    <agile :options="myOptions" ref="carousel" class="carrousel-home">
      <div class="container-card" v-for="(slide, key) in dataContent.slider" :key="key">

        <div class="box-card">
          <p class="super-title" v-if="dataContent.textFixedBig">{{dataContent.textFixedBig}}</p>
          <p class="title-decoration-bottom -white">{{dataContent.textFixedSmall}}</p>
          <p class="description">{{dataContent.descriptionFixed}}</p>
          <router-link :to="dataContent.linkFixed" :title="`Ir a la página ${dataContent.textFixedSmall}`" :alt="`Ir a la página ${dataContent.textFixedSmall}`" class="btn-white">{{dataContent.ctaFixed}}</router-link>
        </div>
        <div class="box-card">
          <p class="title-decoration-bottom">{{slide.title}}</p>
          <p class="description">{{slide.description}}</p>
          <router-link :to="slide.link" :title="`Ir a la página ${slide.title}`" :alt="`Ir a la página ${slide.title}`" class="btn-white">{{slide.cta}}</router-link>
        </div>
      </div>
    </agile>
  </section>

</template>

<script lang="js">
  import {
    VueAgile
  } from "vue-agile";
  export default {
    name: 'carrousel-2',
    components: {
      agile: VueAgile
    },
    props: ['dataContent'],
    mounted() {

    },
    data() {
      return {
        myOptions: {
          responsive: [
            {
              breakpoint: 900,
              settings: {
                navButtons: true,
                slidesToShow:1,
                dots: false,
                centerMode: true,
                unagile: false,
              },
            },
            {
              breakpoint: 800,
              settings: {
                navButtons: true,
                slidesToShow: 1,
                dots: false,
                unagile: false,
                autoplay: false,
                centerMode: false,
              },
            },
            {
              breakpoint: 700,
              settings: {
                navButtons: true,
                slidesToShow: 1,
                dots: false,
                unagile: false,
                centerMode: false,
              }
            },
            {
              breakpoint: 0,
              settings: {
                autoplay: false,
                fade: true,
                navButtons: true,
                slidesToShow: 1,
                dots: false,
                speed: 2000,
                unagile: true
              },
            }],
        },
      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";

  .carrousel-2 {
    .container-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* padding: 60px 0px; */
      position: relative;
      width: 100%;
      height: 380px;

      .box-card {
        background: $blue;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        color: white;
        padding: 30px 10% 30px 100px;
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        transition: all 0.8s ease-in-out;
        width: 55%;
        margin: 0 auto;
        transform: scale(1);
        border-radius: 13px;
        position: absolute;
        left: 2;
        z-index: 1;
        height: 380px;
        /* &:hover {
          transition: all 0.8s ease-in-out;
          transform: scale(1.1);
          z-index: 8;
          position: relative;
          border-radius: 13px;
          box-shadow: 0px 4px 30px rgba(0, 105, 180, 0.3)
        } */

        &:nth-of-type(2n) {

          background-color: white;
          color: $blue;
          position: absolute;
          right: 30px;
          width: 50%;
          z-index: 1;
          height: 250px;
          box-shadow: 0px 4px 30px rgba(0, 105, 180, 0.3);
          left: initial;
          padding: 30px 100px 30px 50px;

          .title {

            &:after,
            &:before {
              background: white;
            }
          }

        }

        .super-title {
          line-height: 0.1em;
          font-size: 5em;
          font-weight: bold;
          color: white;
          opacity: 0.4;
          width: auto;
          display: inline-block;
          font-family: "Frank Ruhl Libre", serif;
          text-align: right;
        }

        &.bg {
          color: white;

          .title {

            &:after,
            &:before {
              background: white;
            }
          }
        }


        .description {
          padding-bottom: 20px;
        }


      }
    }



  }
</style>